module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mailgo/gatsby-browser.js'),
      options: {"plugins":[],"mailgoConfig":{"lang":"pt","showFooter":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-J2KMNW5WJF"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Potentialize - Websites and Apps","short_name":"Potentialize","description":"Software Development, Websites and Applications","lang":"en-US","icon":"src/images/icon.png","start_url":"/","display":"standalone","background_color":"#fff","theme_color":"#1d3557","localize":[{"name":"Potentialize - Sites e Aplicativos","short_name":"Potentialize","description":"Desenvolvimento de Software, Websites e Aplicativos","lang":"pt-BR","start_url":"/pt/"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cee314a69bb3f3164734f97e2417398b"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
